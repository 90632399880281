
// Packages
import { mapGetters } from 'vuex';
import Vue from 'vue';

// Local Imports
import { PresetText } from '../config';

/**
 * Hero text for main page component with accented words.
 */
export default Vue.extend({
  name: 'home-hero-text',

  props: {
    /**
     * Index of image to display.
     */
    text: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    show: true,
  }),

  computed: {
    ...mapGetters('navigation', [
      'isSmaller',
      'isSmall',
      'isMedium',
      'isLarge',
      'isLarger',
    ]),

    /**
     * A way to enforce type?
     *
     * @returns {string} Text converted to PresetText.
     */
    convertedText(): string {
      return (this.text as PresetText[]).map((text: PresetText) => {
        if (text.accent) {
          return `<span style="color: #FEE037;">${text.text}</span>`;
        }
        return text.text;
      }).join(' ');
    },
  },

  watch: {
    convertedText() {
      this.show = false;

      setTimeout(this.reshow, 50);
    },
  },

  methods: {
    reshow(): void {
      this.show = true;
    },
  },
});
