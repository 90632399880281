
// Packages
import Vue from 'vue';

/**
 * Dot component for home view.
 */
export default Vue.extend({
  name: 'home-dots',

  props: {
    /**
     * Id of component for keys.
     */
    id: {
      type: String,
      default: (Math.random() + 1).toString(36).substring(7),
    },

    /**
     * Width of dots in component.
     */
    width: {
      type: Number,
      default: 0,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    /**
     * Height of dots in component.
     */
    height: {
      type: Number,
      default: 0,
    },

    /**
     * How filled the dots should be.
     */
    fillRatio: {
      type: Number,
      default: 0.5,
    },

    /**
     * How many dots should be yellow.
     */
    coloredRatio: {
      type: Number,
      default: 0.5,
    },
  },

  data: () => ({
    /**
     * Dot array.
     */
    map: [] as number[][],
  }),

  computed: {
    /**
     * The width as a pixel value.
     */
    fullWidth(): number {
      return (this.width * 2) * 15;
    },

    /**
     * The height of the pixel value.
     */
    fullHeight(): number {
      return (this.height * 2) * 15;
    },
  },

  created() {
    for (let i = 0; i < this.height; i += 1) {
      this.map.push([]);
      for (let j = 0; j < this.width; j += 1) {
        if (Math.random() < this.fillRatio) {
          if (Math.random() < this.coloredRatio) {
            this.map[i].push(2);
          } else {
            this.map[i].push(1);
          }
        } else {
          this.map[i].push(0);
        }
      }
    }
  },
});
