
// Packages
import {
  mapActions,
  mapGetters,
} from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'app-bar-quote-button',

  methods: {
    ...mapActions('navigation', [
      'goToQuote',
    ]),
  },

  computed: {
    ...mapGetters('navigation', [
      'appBarShouldBeDark',
    ]),

    /**
     * Color of the button.
     *
     * @type {string}
     */
    buttonColor(): string {
      if (this.appBarShouldBeDark) {
        return '#000000';
      }
      return '#FFFFFF';
    },
  },
});
