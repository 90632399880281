var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.component},_vm._l((_vm.images),function(image,index){return _c('div',{key:`background-image-${index}`,class:[
      _vm.$style.background,
      {
        [_vm.$style.first]: _vm.first,
        [_vm.$style.active]: index === _vm.active,
        [_vm.$style.last]: index === _vm.active - 1 || (_vm.active === 0 && index === _vm.images.length - 1),
      },
    ],style:({
      backgroundImage: _vm.resolveImage(image),
    })})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }