
// Packages
import { mapGetters } from 'vuex';
import Vue from 'vue';

/**
 * Dot component for home view.
 */
export default Vue.extend({
  name: 'home-rings',

  computed: {
    ...mapGetters('navigation', [
      'isSmaller',
      'isSmall',
      'isMedium',
      'isLarge',
      'isLarger',
    ]),
  },
});
