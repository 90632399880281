
// Packages
import {
  mapActions,
  mapGetters,
} from 'vuex';
import Vue from 'vue';

// Local Imports
import AppBarQuoteButton from './app-bar-quote-button.vue';
import AppBarMenuButton from './app-bar-menu-button.vue';

export default Vue.extend({
  name: 'app-bar',

  components: {
    AppBarQuoteButton,
    AppBarMenuButton,
  },

  computed: {
    ...mapGetters('navigation', [
      'appBarShouldBeDark',
      'isSmaller',
      'isSmall',
      'isMedium',
      'isLarge',
      'isLarger',
    ]),
  },

  methods: {
    ...mapActions('navigation', [
      'goToHome',
    ]),
  },
});
