var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.component},[_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style['title-wrapper'],on:{"click":_vm.goToHome,"keydown":_vm.goToHome}},[_c('span',{class:{
            [_vm.$style.bold]: true,
            [_vm.$style.dark]: _vm.appBarShouldBeDark,
          }},[_vm._v(" United ")]),_c('span',{class:{
            [_vm.$style.bold]: true,
            [_vm.$style.dark]: _vm.appBarShouldBeDark,
          }},[_vm._v(" Printing ")]),_c('span',{class:{
          [_vm.$style.dark]: _vm.appBarShouldBeDark,
        }},[_vm._v(" Company ")])]),(_vm.isLarge || _vm.isLarger)?_c('span',{class:{
        [_vm.$style.dark]: _vm.appBarShouldBeDark,
        [_vm.$style['vertical-spacer']]: true,
      }}):_vm._e(),(_vm.isLarge || _vm.isLarger)?_c('span',{class:{
        [_vm.$style.dark]: _vm.appBarShouldBeDark,
        [_vm.$style.location]: true,
      }},[_vm._v(" Charlotte, NC ")]):_vm._e(),_c('v-spacer',{class:{
        [_vm.$style.dark]: _vm.appBarShouldBeDark,
      }}),(!(_vm.isSmaller || _vm.isSmall))?_c('app-bar-quote-button'):_vm._e(),_c('app-bar-menu-button')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }