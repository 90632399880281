
// Packages
import { mapActions } from 'vuex';
import Vue from 'vue';

/**
 * Hero text for main page component with accented words.
 */
export default Vue.extend({
  name: 'home-request-quote-popup',

  methods: {
    ...mapActions('navigation', [
      'goToQuote',
    ]),
  },
});
