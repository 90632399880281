
// Packages
import {
  mapActions,
  mapGetters,
} from 'vuex';
import Vue from 'vue';

// Local Imports
import {
  PRESETS,
  Preset,
  PresetText,
  PRESET_TIMING,
} from '@/views/home/config';
import Background from '@/views/home/components/background.vue';
import HeroText from '@/views/home/components/hero-text.vue';
import HomeFeature from '@/views/home/components/feature.vue';
import Dots from './components/dots.vue';
import Rings from './components/rings.vue';
import RequestQuotePopup from './components/request-quote-popup.vue';

export default Vue.extend({
  name: 'home-view',

  components: {
    Background,
    HeroText,
    HomeFeature,
    Dots,
    Rings,
    RequestQuotePopup,
  },

  data: () => ({
    presets: [] as Preset[],

    active: 0,
  }),

  created() {
    this.handlePageLoad({ name: this.$route.name });
    this.presets = PRESETS;

    setTimeout(this.changePreset, PRESET_TIMING);
  },

  computed: {
    ...mapGetters('navigation', [
      'isSmaller',
      'isSmall',
      'isMedium',
      'isLarge',
      'isLarger',
    ]),

    hero(): PresetText[] {
      if (this.presets.length) {
        return this.presets[this.active].title;
      }
      return [];
    },

    supplementaryText(): string {
      if (this.presets.length) {
        return this.presets[this.active].supplementaryText.slice(0, 200);
      }
      return '';
    },

    isRings(): boolean {
      if (this.presets.length) {
        return this.presets[this.active].rings;
      }
      return false;
    },

    isVeteran(): boolean {
      if (this.presets.length) {
        return this.presets[this.active].veteran;
      }
      return false;
    },
  },

  methods: {
    ...mapActions('navigation', [
      'handlePageLoad',
      'goToAbout',
    ]),

    changePreset() {
      this.active = (this.active + 1) % this.presets.length;

      setTimeout(this.changePreset, PRESET_TIMING);
    },
  },
});
