var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.$style.content,
    {
      [_vm.$style.smaller]: _vm.isSmaller,
      [_vm.$style.small]: _vm.isSmall,
      [_vm.$style.medium]: _vm.isMedium,
      [_vm.$style.large]: _vm.isLarge,
    },
  ]},[(_vm.show)?_c('span',{class:_vm.$style.word,domProps:{"innerHTML":_vm._s(_vm.convertedText)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }