var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.$style.component,
    {
      [_vm.$style.dark]: _vm.dark
    },
  ],style:({
    'width': `${_vm.fullWidth}px`,
    'height': `${_vm.fullHeight}px`,
  })},_vm._l((_vm.map),function(row,rowIndex){return _c('div',{key:`orb-${_vm.id}-row-${rowIndex}`,class:_vm.$style.row},_vm._l((row),function(orb,index){return _c('div',{key:`orb-${_vm.id}-row-${rowIndex}-${index}`,class:[
        _vm.$style.orb,
        {
          [_vm.$style.white]: orb === 1,
          [_vm.$style.accent]: orb === 2,
        },
      ],style:({
        '--animation-delay': index,
      })})}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }