
// Packages
import {
  mapActions,
  mapGetters,
} from 'vuex';
import Vue from 'vue';

// Local Imports
import AppBar from './components/ui/app-bar/app-bar.vue';

export default Vue.extend({
  name: 'App',

  components: {
    AppBar,
  },

  computed: {
    ...mapGetters('navigation', [
      'isLarge',
      'isLarger',
      'getPage',
    ]),
  },

  created() {
    this.handleResize();
  },

  methods: {
    ...mapActions('navigation', [
      'handleResize',
    ]),
  },
});
