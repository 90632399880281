
// Packages
import {
  mapActions,
  mapGetters,
} from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'app-bar-quote-button',

  data: () => ({
    active: false,
  }),

  computed: {
    ...mapGetters('navigation', [
      'getPage',
      'appBarShouldBeDark',
      'isSmaller',
      'isSmall',
      'isMedium',
      'isLarge',
      'isLarger',
    ]),

    /**
     * Color of the button.
     *
     * @type {string}
     */
    buttonColor(): string {
      if (this.appBarShouldBeDark) {
        return '#000000';
      }
      return '#FFFFFF';
    },
  },

  methods: {
    ...mapActions('navigation', [
      'goToHome',
      'goToAbout',
      'goToServices',
      'goToContact',
      'goToQuote',
    ]),

    activate() {
      if (!this.active) {
        this.active = true;
      }
    },

    toggleActive() {
      this.active = !this.active;
    },

    /**
     * Handles button click.
     * @param {Record<string, string>} args Event arguments.
     * @param {string} args.name Page name.
     */
    handleClick(name: string) {
      switch (name) {
        case 'contact':
          this.goToContact();
          break;
        case 'about':
          this.goToAbout();
          break;
        case 'services':
          this.goToServices();
          break;
        case 'quote':
          this.goToQuote();
          break;
        default:
          this.goToHome();
      }
      this.active = false;
    },
  },
});
