var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.$style.component,
    {
      [_vm.$style.smaller]: _vm.isSmaller,
      [_vm.$style.small]: _vm.isSmall,
      [_vm.$style.medium]: _vm.isMedium,
      [_vm.$style.large]: _vm.isLarge,
    },
  ]},[_c('v-btn',{class:_vm.$style.button,attrs:{"color":_vm.buttonColor,"outlined":"","dark":""},on:{"click":_vm.activate}},[_vm._v(" Menu ")]),(_vm.active)?_c('div',{class:_vm.$style.filter},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggleActive),expression:"toggleActive"}],class:_vm.$style.menu},[_c('span',{class:{
          [_vm.$style.active]: _vm.getPage === 'home',
        },style:({
          '--index': 0,
        }),on:{"keydown":_vm.goToContact,"click":function($event){return _vm.handleClick('home')}}},[_vm._v(" Home ")]),_c('span',{class:{
          [_vm.$style.active]: _vm.getPage === 'about',
        },style:({
          '--index': 1,
        }),on:{"keydown":_vm.goToContact,"click":function($event){return _vm.handleClick('about')}}},[_vm._v(" About ")]),_c('span',{class:{
          [_vm.$style.active]: _vm.getPage === 'services',
        },style:({
          '--index': 2,
        }),on:{"keydown":_vm.goToContact,"click":function($event){return _vm.handleClick('services')}}},[_vm._v(" Services ")]),_c('span',{class:{
          [_vm.$style.active]: _vm.getPage === 'contact',
        },style:({
          '--index': 3,
        }),on:{"keydown":_vm.goToContact,"click":function($event){return _vm.handleClick('contact')}}},[_vm._v(" Contact Us ")]),_c('span',{class:{
          [_vm.$style.active]: _vm.getPage === 'quote',
        },style:({
          '--index': 4,
        }),on:{"keydown":_vm.goToContact,"click":function($event){return _vm.handleClick('quote')}}},[_vm._v(" Request a Quote ")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }