
// Packages
import Vue from 'vue';

// Local Imports
import {
  PRESETS,
  Preset,
} from '../config';

export default Vue.extend({
  name: 'home-background',

  props: {
    /**
     * Index of image to display.
     */
    active: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    images: [] as string[],

    first: true,
  }),

  created() {
    this.images = PRESETS.map((preset: Preset) => preset.background);
  },

  methods: {
    /**
     * Resolves an image name to it's item.
     *
     * @param {string} name Name of the image file.
     */
    resolveImage(name: string) {
      // const images = require.context(
      //   '../../../assets/images',
      //   false,
      //   /\.png$/,
      // );

      // return images(`./${name}.png`);

      return `url(./img/${name}.png)`;
    },
  },

  watch: {
    active() {
      this.first = false;
    },
  },
});
